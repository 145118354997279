import Vue from 'vue';
import VueRouter from 'vue-router';
import { hasAuthUser, removeStorage, getAuthUser } from '@/Util/auth';
import PublicLayout from '@/layouts/main';
import AdminLayout from "@/layouts/admin";

Vue.use(VueRouter);

const routes = [
    {
        path: '/admin',
        redirect: '/admin/dashboard',
        component: AdminLayout,
        meta: {
            authAdminRequired: true,
        },
        children: [
            {
                path: 'dashboard',
                name: 'Dashboard',
                meta: {
                    breadcrumbs: 'Dashboard',
                },
                component: () => import('@/views/admin/dashboard'),
            },
            {
                path: 'profile',
                name: 'AdminUserProfile',
                meta: {
                    breadcrumbs: 'Profile',
                },
                component: () => import('@/views/admin/profile'),
            },
            {
                path: 'user/:operation/:id?',
                name: 'AdminUserForm',
                meta: {
                    breadcrumbs: 'Users Form',
                },
                component: () => import('@/views/admin/users/form'),
            },
            {
                path: 'users',
                name: 'AdminUser',
                meta: {
                    breadcrumbs: 'Users',
                },
                component: () => import('@/views/admin/users'),
            },
            {
                path: 'teams',
                name: 'AdminTeam',
                meta: {
                    breadcrumbs: 'Teams',
                },
                component: () => import('@/views/admin/teams'),
            },
            {
                path: 'team/:operation/:id?',
                name: 'AdminTeamForm',
                meta: {
                    breadcrumbs: 'Teams Form',
                },
                component: () => import('@/views/admin/teams/form'),
            },
            {
                path: 'news',
                name: 'AdminNews',
                meta: {
                    breadcrumbs: 'News',
                },
                component: () => import('@/views/admin/news'),
            },
            {
                path: 'news/:operation/:id?',
                name: 'AdminNewsForm',
                meta: {
                    breadcrumbs: 'News Form',
                },
                component: () => import('@/views/admin/news/form'),
            },
            {
                path: 'followers',
                name: 'AdminFollowers',
                meta: {
                    breadcrumbs: 'Followers',
                },
                component: () => import('@/views/admin/followers'),
            },
            {
                path: 'projects',
                name: 'AdminProject',
                meta: {
                    breadcrumbs: 'Projects',
                },
                component: () => import('@/views/admin/projects'),
            },
            {
                path: 'project/:operation/:id?',
                name: 'AdminProjectForm',
                meta: {
                    breadcrumbs: 'Project Review',
                },
                component: () => import('@/views/admin/projects/review_project'),
            },
        ],
    },
    {
        path: '/auth',
        component: PublicLayout,
        redirect: 'login',
        meta: {
            authRequired: false,
        },
        children: [
            {
                path: '/admin/login',
                name: 'AdminLogin',
                component: () => import('@/views/admin/auth/login'),
            },
            {
                path: '/login',
                name: 'Login',
                component: () => import('@/views/auth/login'),
            },
            {
                path: '/forgot-password',
                name: 'ForgotPassword',
                component: () => import('@/views/auth/forgot_password'),
            },
            {
                path: '/resetpass',
                name: 'New Password',
                query: {
                    email: '',
                    token: '',
                },
                component: () => import('./../views/auth/newpass'),
            },
            {
                path: '/register',
                name: 'Register',
                component: () => import('@/views/auth/registration'),
            },
        ],
    },
    {
        path: '/',
        component: PublicLayout,
        meta: {
            authRequired: false,
        },
        children: [
            {
                path: '',
                name: 'home',
                component: () => import('./../views/homepage'),
            },
            {
                path: '/how-it-works',
                name: 'howItWorks',
                component: () => import('./../views/howItWorks'),
            },
            {
                path: '/news',
                name: 'news',
                component: () => import('./../views/news'),
            },
            {
                path: '/our-story',
                name: 'ourStory',
                component: () => import('./../views/ourStory'),
            },
            {
                path: '/browse-projects/:id?',
                name: 'browseProjects',
                component: () => import('./../views/browseProjects'),
            },
            {
                path: '/projects/:category_slug/:link',
                name: 'projectPrmotionURL',
                component: () => import('./../views/projectPage'),
            },
            // {
            //     path: '/projects-page',
            //     name: 'projectsPage',
            //     component: () => import('./../views/projectsPage'),
            // },
            {
                path: '/project-page/:id',
                name: 'projectPage',
                component: () => import('./../views/projectPage'),
            },
        ],
    },
    {
        path: '/',
        component: PublicLayout,
        meta: {
            authRequired: true,
        },
        children: [
            {
                path: '/project/:id?',
                name: 'project',
                component: () => import('./../views/project/new'),
            },
            {
                path: '/project/:id',
                name: 'projectId',
                component: () => import('./../views/project/new'),
            }, //use for only pushing one time from basic to next
            // {
            //     path: '/new-project',
            //     name: 'newProject',
            //     component: () => import('./../views/newProject'),
            // },
            // {
            //     path: '/new-project/:id?',
            //
            //     name: 'newProjectId',
            //     component: () => import('./../views/newProject'),
            // },
            {
                path: '/project-preview/:id',
                name: 'projectPreview',
                component: () => import('@/views/projectPreview'),
            },
            {
                path: '/project-management/:id',
                name: 'projectManagement',
                component: () => import('@/views/projectManagement'),
            },
            {
                path: '/account',
                name: 'Account',
                component: () => import('./../views/account'),
            },
            {
                path: '/settings',
                name: 'Setting',
                component: () => import('./../views/settings'),
            },
        ],
    },
    {
        path: '*',
        beforeEnter: (to, from, next) => {
            next('/404');
        },
    },
    {
        path: '/404',
        name: '404',
        component: () => import('./../views/error'),
    },
];

const router = new VueRouter({
    mode: 'history',
    base: '/',
    routes,
    scrollBehavior(to, from, savedPosition) {
        return { x: 0, y: 0 };
    },
});

router.beforeEach((to, from, next) => {
    if (
        to.matched.some(record => record.meta.authAdminRequired)
        && hasAuthUser()
        && getAuthUser().role !== 'admin'
    ) {
        next({
            name: 'home',
        });
    }
    if (
        ( to.matched.some(record => record.meta.authRequired)
            || to.matched.some(record => record.meta.authAdminRequired) )
        && !hasAuthUser()
    ) {
        removeStorage('auth');
        next({
            name: 'home',
        });
    }
    next();
});

export default router;
