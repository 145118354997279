<template>
    <div class="my-2">
        <i class="bi bi-hourglass text-warning" v-if="status == 'pending'"></i>
        <i class="bi bi-sign-do-not-enter text-danger" v-if="status == 'cancel'"></i>
        <i class="bi bi-check-circle text-success" v-if="status == 'complete'"></i>
    </div>
</template>

<script>
export default {
    props: ['status'],
    data() {
        return {};
    }
};
</script>
