<template>
    <div class="my-2">
        <span v-if="status == 'draft'" class="bg-dark text-white p-2">{{ _.capitalize(status) }}</span>
        <span v-if="status == 'review'" class="bg-warning text-white p-2">{{ _.capitalize(status) }}</span>
        <span v-if="status == 'approved'" class="bg-info text-white p-2">{{ _.capitalize(status) }}</span>
        <span v-if="status == 'launched'" class="bg-primary text-white p-2">{{ _.capitalize(status) }}</span>
    </div>
</template>

<script>
export default {
    props: ['status'],
    data() {
        return {};
    }
};
</script>
