import Vue from 'vue';
import axios from 'axios';
import VueAxios from 'vue-axios';
import App from './App.vue';
import router from './router';
import store from './store';
import _ from 'lodash';
import { handleSyncRequestLoader } from '@/Util/auth';
import 'bootstrap/dist/css/bootstrap.css';
// import * as bootstrap from 'bootstrap/dist/js/bootstrap';
import * as bootstrap from 'bootstrap';
import 'bootstrap-icons/font/bootstrap-icons.css';
import Snotify, { SnotifyPosition } from 'vue-snotify';
import 'vue-snotify/styles/material.css'; // or dark.css or simple.css
import CKEditor from '@ckeditor/ckeditor5-vue';
import { DataTable } from '@andresouzaabreu/vue-data-table';
import '@andresouzaabreu/vue-data-table/dist/DataTable.css';
import vSelect from 'vue-select';
import 'vue-select/dist/vue-select.css';
import { Datetime } from 'vue-datetime';
import 'vue-datetime/dist/vue-datetime.css';
import { Settings } from 'luxon';
import VueSocialauth from 'vue-social-auth';
import VueAppleSignin from 'vue-apple-signin';
import './assets/scss/main.scss';
import BootstrapVue from 'bootstrap-vue';
import CrudTable from "@/components/CrudTable";
import '@fortawesome/fontawesome-free/css/all.css';
import SingleImageUpload from "@/components/SingleImageUpload";
import { dateFormat } from "@/Util/auth";
import ProjectStatus from "@/components/ProjectStatus";
import TransactionStatus from "@/components/TransactionStatus";
import VueApexCharts from 'vue-apexcharts';

export const EventBus = new Vue();
Settings.defaultLocale = 'en';
window.bootstrap = bootstrap;
Vue.component('datetime', Datetime);
Vue.component('v-select', vSelect);
Vue.component('data-table', DataTable);
Vue.component('crud-table', CrudTable);
Vue.component('single-image-upload', SingleImageUpload);
Vue.component('project-status', ProjectStatus);
Vue.component('transaction-status', TransactionStatus);
Vue.use(BootstrapVue);
Vue.use(CKEditor);
Vue.use(VueApexCharts);
const options = {
    toast: {
        position: SnotifyPosition.rightTop,
    },
};
const AuthOption = {
    providers: {
        google: {
            clientId: process.env.VUE_APP_GOOGLE_CLIENT_ID,
            redirectUri: `${ process.env.VUE_APP_URL }/admin/dashboard`, // Your client app URL
        },
        facebook: {
            clientId: process.env.VUE_APP_GOOGLE_CLIENT_ID,
            redirectUri: `${ process.env.VUE_APP_URL }/admin/dashboard`, // Your client app URL
        },
    },
};
const AppleAuthOptions = {
    clientId: process.env.VUE_APP_APPLE_CLIENT_ID,
    redirectUri: `${ process.env.VUE_APP_URL }/admin/dashboard`, // Your client app URL
};

Vue.use(Snotify, options);
Vue.use(VueAxios, axios);
Vue.use(VueSocialauth, AuthOption);
Vue.use(VueAppleSignin, AppleAuthOptions);

Vue.prototype._ = _;
Vue.config.productionTip = false;
Vue.prototype.$global = {
    dateFormat: (date, format = 'DD/MM/YYYY') => ( ( date ) ? dateFormat(date, format) : null ),
};

new Vue({
    store,
    router,
    render: h => h(App),
    created() {
        handleSyncRequestLoader(store, process.env.VUE_APP_API_URL);
    },
}).$mount('#app');
