<template>
    <footer class="row m-0 base-footer text-white">
        <div class="container">
            <div class="row">
                <div class="col-3">
                    <ul class="nav flex-column">
                        <a href="/" class="d-flex align-items-center mb-3 link-dark text-decoration-none">
                            <img src="../assets/img/medicrowd_logo_wh.svg" alt="Logo" class="footer-logo" />
                        </a>
                        <a href="" class="text-white fs-3 text-decoration-none footer-connect"
                        >Connect with us
                            <svg class="icon icon-long-arrow ms-5" width="39.5" height="14">
                                <use href="../assets/img/icons.svg#icon-long-arrow"></use>
                            </svg>
                        </a>
                    </ul>
                </div>
                <div class="col-3">
                    <ul class="nav flex-column">
                        <h5 class="fw-normal">Medicrowd</h5>
                        <li class="nav-item"><a href="#" class="nav-link p-0 lh-lg">Updates</a></li>
                        <li class="nav-item"><a href="#" class="nav-link p-0 lh-lg">Pricing</a></li>
                        <li class="nav-item"><a href="#" class="nav-link p-0 lh-lg">Tutorials</a></li>
                        <li class="nav-item"><a href="#" class="nav-link p-0 lh-lg">Teams</a></li>
                    </ul>
                </div>
                <div class="col-3">
                    <h5 class="fw-normal">Rescources</h5>
                    <ul class="nav flex-column">
                        <li class="nav-item"><a href="#" class="nav-link p-0 lh-lg">Examples</a></li>
                        <li class="nav-item"><a href="#" class="nav-link p-0 lh-lg">Sessions</a></li>
                        <li class="nav-item"><a href="#" class="nav-link p-0 lh-lg">Program</a></li>
                    </ul>
                </div>
                <div class="col-3">
                    <h5 class="fw-normal">About</h5>
                    <ul class="nav flex-column">
                        <li class="nav-item"><a href="#" class="nav-link p-0 lh-lg">Community</a></li>
                        <li class="nav-item"><a href="#" class="nav-link p-0 lh-lg">Company</a></li>
                        <li class="nav-item"><a href="#" class="nav-link p-0 lh-lg">Careers</a></li>
                    </ul>
                </div>
            </div>
        </div>
        <div class="col-12 col-md-6 mt-60 d-none d-md-flex">
            <!--      <p class="fs-18 mt-auto mb-0">Copyright Element 2023</p>-->
        </div>
        <div class="col-12 col-md-6 mt-60">
            <p class="fs-18 mt-auto mb-0 text-center">Designed by Checker Software</p>
        </div>
    </footer>
</template>

<script>
export default {
    name: 'Footer',
    data() {
        return {};
    },
};
</script>
<style lang="scss" scoped>
@import '../assets/scss/components/footer.scss';
</style>
