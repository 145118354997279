<template>
    <div>
        <div class="image">
            <div class="image rounded-circle" @click.prevent="openCloseImageUploadModel">
                <span v-if="previewImage">
                    <img :src="previewImage" alt="User Image" v-if="previewImage" class="form-control" width="100" />
                </span>
                <span class="title" v-else>
                    <svg width="20" height="20">
                        <use href="@/assets/img/icons.svg#icon-add"></use>
                    </svg>
                </span>
            </div>
        </div>
        <p class="fs-16 text-main text-decoration-underline m-0" @click="openCloseImageUploadModel">
            {{ previewImage ? 'Edit' : 'Add' }} {{ title }}
        </p>

        <div class="modal fade pictureModal" :id="`${id}`" tabindex="-1" aria-hidden="true" ref="modal">
            <div class="modal-dialog modal-dialog-centered">
                <div class="modal-content">
                    <div class="modal-body p-0 row">
                        <div class="row col-12 p-0 m-0 d-flex align-items-center mb-4">
                            <div class="row col-12 p-0 m-0 d-flex align-items-center mb-4">
                                <h5 class="col fw-bold m-0">{{ title }}</h5>
                                <button type="button"
                                        class="rounded btn-close opacity-1 align-top align-self-start ms-auto col-auto me-2"
                                        @click="openCloseImageUploadModel('close')"
                                ></button>
                            </div>
                            <form class="row col-12 p-0 m-0">
                                <div class="row mx-0 mb-4">
                                    <label
                                        :class="!showImgCropper?`drop-area open-cropper visually-hidden`:`drop-area open-cropper`"
                                        ref="dropElement"
                                        @dragover="callDragOverFuns"
                                        @dragleave="callDragLeaveFuns"
                                        @drop="callDropFuns"
                                        @dragenter="callDragEnterFuns"
                                        @mouseover="callMouseOverFuns"
                                        @mouseout="callMouseOutFuns"

                                    >
									<span class="icon-circle">
										<svg class="icon icon-imagesmode" width="24" height="24">
											<use :href="icons + `#icon-imagesmode`"></use>
										</svg>
									</span>

                                        <span class="info">Drop an image here, or select a file. It must be a JPG, PNG, GIF,
										TIFF, or BMP,
										no larger than 10 MB.</span>
                                        <input
                                            type="file"
                                            class="fileElem d-none"
                                            id="fileElem"
                                            accept="image/*"
                                            ref="fileInput"
                                            :key="fileInputKey"
                                            @change="handleChangeFile"
                                        />
                                        <div class="gallery">
                                        </div>
                                    </label>
                                    <div v-if="showImgCropper"
                                        id="container-crop"
                                        :class="`visually-hidden`"
                                    >
                                        <img :src="cropperImageUrl" ref="cropperImage">
                                    </div>
                                </div>
                                <div class="col-12 d-flex flex-column justify-content-end">
                                    <button
                                        class="main-btn w-100 crop-image-finish fs-20 h-44"
                                        href="#"
                                        :disabled="loading"
                                        @click.prevent="uploadImage">
                                        Upload
                                    </button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { getAuthUser } from '@/Util/auth';
import icons from '@/assets/img/icons.svg';
import Cropme from 'cropme/dist/cropme';
import Cropper from 'cropperjs';

const initialState = () => {
    return {
        loading: false,
        showModal: true,
        modal: null,
        containerId: 'container-crop',
        icons: icons,
        cropElement: null,
        dropArea: null,
        showImgCropper: false,
        fileInputKey: 0,
        cropperImageUrl: null,
        previewImage: null
    };
};
export default {
    props: ['title', 'id'],
    name: 'SingleImageUpload',
    data() {
        return initialState();
    },
    mounted() {
        this.dropArea = this.$refs.dropElement;
        if ( _.isEmpty(getAuthUser()) ) {
            this.$router.push({ name: 'Login' });
        }
        if ( this.id ) {
            const modalElem = document.querySelector(`#${ this.id }`);
            this.modal = new window.bootstrap.Modal(modalElem);
        }
    },
    methods: {
        refreshModal() {
            if ( this.id ) {
                const modalElem = document.querySelector(`#${ this.id }`);
                this.modal = new window.bootstrap.Modal(modalElem);
            }
        },
        async openCloseImageUploadModel(type = null) {
            if ( this.id ) {

                if ( !this.modal ) {
                    this.refreshModal();
                }

                if ( type == 'close' ) {
                    this.modal.hide();
                    this.showModal = !this.showModal;
                } else {
                    this.showModal = true;
                    this.modal.show();
                }

                this.loading = !this.loading;
                this.showImgCropper = this.showModal;
                if ( this.cropElement !== null ) {
                    const element = document.querySelector("#container-crop");
                    if ( element ) {
                        element.classList.toggle('visually-hidden');
                        this.fileInputKey++;//to re-render file input to allow change event on the same input
                        this.$refs.fileInput.value = '';// to remove previously selected image
                        this.cropElement.destroy();
                        this.cropElement = null;
                    }
                }
            }
        },
        async uploadImage() {
            try {
                const croppedCanvas = await this.cropElement.getCroppedCanvas();
                const roundedCanvas = await this.getRoundedCanvas(croppedCanvas);
                const croppedbase64Img = roundedCanvas.toDataURL('image/png', 0.8);
                const fileObject = await this.convertBase64ToFile(croppedbase64Img);
                this.loading = true;
                this.uploadCroppedImage(fileObject);
            } catch (error) {
                this.loading = false;
                console.error(error);
            }
        },
        async convertBase64ToFile(base64String) {
            const res = await fetch(base64String);
            const blob = await res.blob();
            // const matches = base64String.match(/^data:(.+);base64,/);
            let mime = 'image/png';
            // if ( matches && matches.length > 1 ) {
            //     mime = matches[1];
            // }
            const ext = mime.split('/')[1];
            const file = new File([blob], `image.${ ext }`, { type: mime });
            return file;
        },
        uploadPreviewImage(file) {
            this.previewImage = file;
        },
        async uploadCroppedImage(file) {
            this.$emit('image-selected', file);
            this.previewImage = URL.createObjectURL(file);
            await this.openCloseImageUploadModel('close');
        },
        callDragOverFuns(event) {
            this.preventDefaults(event);
            this.highlight(event);
        },
        callDragLeaveFuns(event) {
            this.preventDefaults(event);
            this.unhighlight(event);
        },
        callDropFuns(event) {
            this.preventDefaults(event);
            this.unhighlight(event);
            this.handleDrop(event);
        },
        callDragEnterFuns(event) {
            this.preventDefaults(event);
            this.highlight(event);
        },
        callMouseOverFuns(event) {
            this.highlight(event);
        },
        callMouseOutFuns(event) {
            this.unhighlight(event);
        },
        preventDefaults(e) {
            e.preventDefault();
            e.stopPropagation();
        },
        highlight(e) {
            this.dropArea.classList.add('highlight');
        },
        unhighlight(e) {
            this.dropArea.classList.remove('highlight');
        },
        handleDrop(e) {
            const dt = e.dataTransfer;
            const files = dt.files;
            this.handleFiles(files);
        },
        handleChangeFile(event) {
            let files;
            if ( event.target ) {
                files = event.target.files;
                this.handleFiles(files);
            }
        },
        handleFiles(files) {
            files.forEach(this.uploadFile);
        },
        uploadFile(file, i) {
            if ( this.dropArea.classList.contains('open-cropper') ) {
                this.UploadCrop(file);
            }
        },
        async UploadCrop(file) {
            if ( file.type.startsWith('image/') ) {
                this.cropperImageUrl = URL.createObjectURL(file);
                this.dropArea.classList.add('full');
                this.$nextTick(() => {
                    this.showCropper();
                });
            } else {
                this.$snotify.warning("File must be an Image!");

            }
        },
        async showCropper() {
            this.showImgCropper = true;
            const element = this.dropArea.parentNode.querySelector('#container-crop');
            element.classList.toggle('visually-hidden');
            this.dropArea.classList.add('visually-hidden');
            //document.querySelector('.crop-image-finish').classList.toggle('disabled');
            this.loading = false;
            this.cropElement = new Cropper(this.$refs.cropperImage, {
                aspectRatio: 1,
                viewMode: 0,
                dragMode: 'move',
                modal: true,
                highlight: true,
                background: true, // show grid background
                movable: true,
                cropBoxMovable: false,
                cropBoxResizable: false,
            });
        },
        onChange() {
            let file = this.$refs.uploadedFile.files[0];
            this.$emit('image-selected', file);
        },
        openModal() {
            const modalElem = document.querySelector(`#${ this.id }`);
            new window.bootstrap.Modal(modalElem).show();
        },
        removePreviewImage() {
            this.previewImage = null;
        },
        addPreviewImage(file) {
            this.previewImage = file;
        },
        getRoundedCanvas(sourceCanvas) {
            const canvas = document.createElement('canvas');
            const context = canvas.getContext('2d');
            const scale = 0.6;
            const width = sourceCanvas.width * scale;
            const height = sourceCanvas.height * scale;
            canvas.width = width;
            canvas.height = height;
            context.imageSmoothingEnabled = true;
            context.drawImage(sourceCanvas, 0, 0, width, height);
            context.globalCompositeOperation = 'destination-in';
            context.beginPath();
            context.arc(width / 2, height / 2, Math.min(width, height) / 2, 0, 2 * Math.PI, true);
            context.fill();
            return canvas;
        }
    }
};
</script>

<style lang="scss">
/**temporary Changes By developer */
#container-crop > img {
    display: none
}

.cropper-container .cropper-view-box,
.cropper-container .cropper-face {
    border-radius: 50%;
}

@import 'cropperjs/dist/cropper.min.css';
@import '@/assets/scss/components/uploadImage.scss';
</style>
