<template>
    <div class="image">
        <div
            class="box image rounded-circle dropdown-toggle"
            @click="showDropdown = !showDropdown"
            ref="dropDownElement"
        >
            <!-- data-bs-toggle="dropdown"
                        aria-expanded="false"
                        data-bs-autoClose="true"
                        data-bs-boundary="body"
                        data-bs-display="static"
                        data-bs-offset="0,0" -->
            <img :src="profileImgUrl" alt="User Image" v-if="profileImgUrl" />
            <span class="title" v-else>{{ nameFirstletters ? nameFirstletters : 'NA' }}</span>
        </div>

        <div class="dropdown-menu" :class="{ show: showDropdown }" aria-labelledby="dropdown">
            <h4 class="text-midnight mb-4">Your Account</h4>
            <button
                type="button"
                class="btn-close d-block d-md-none"
                aria-label="Close"
                @click="showDropdown = !showDropdown"
            ></button>
            <div class="link-group mb-32">
                <router-link
                    :to="{ name: 'Account' }"
                    class="mb-2 d-block text-ship-grey text-decoration-none"
                >Profile
                </router-link>
                <router-link
                    :to="{ name: 'Setting' }"
                    class="mb-2 d-block text-ship-grey text-decoration-none"
                >Settings
                </router-link>
            </div>
            <div class="link-group mb-32">
                <a href="" class="mb-2 d-block text-ship-grey text-decoration-none">Favourite Projects</a>
                <a href="" class="mb-2 d-block text-ship-grey text-decoration-none">Recommended for you</a>
            </div>
            <hr class="divider mb-32" />
            <h4 class="text-midnight mb-3">Created Projects</h4>
            <div class="project-list mb-32" v-if="projects.length">
                <p class="d-flex align-items-center mb-3" v-for="project in projects" :key="project.id">
                    <a
                        href="javascript:void(0);"
                        @click="redirectToProject(project.id)"
                        class="me-4 text-black text-decoration-none"
                    >{{ project.title }}
                    </a>
                    <span class="badge status rounded-pill draft" :class="project.status.className">{{
                            project.status.name
                        }}</span>
                </p>
            </div>
            <button class="btn main-btn px-3" @click="redirectToProject()">
                New
                <svg class="icon icon-folder me-2" width="20" height="20">
                    <use xlink:href="../assets/img/icons.svg#icon-plus"></use>
                </svg>
            </button>

            <a href="javascript:void(0);"
               class="fs-16 text-main mb-32 mt-4 d-block"
               @click="redirectToMyProject()">
                View all
            </a>
            <hr class="divider mb-32" />
            <div class="link-group">
                <a
                    href="javascript:void(0);"
                    class="mb-2 d-block text-ship-grey text-decoration-none"
                    @click="handleSubmitLogout()"
                >Log Out</a
                >
            </div>
        </div>
    </div>
</template>

<script>
import { hasAuthUser, removeStorage, setStorage } from '@/Util/auth';
import { mapGetters } from 'vuex';
import { request } from '@/Util/Request';
import { EventBus } from '@/event-bus';

const STATUS_CLASS = {
    draft: 'Draft',
    approved: 'Approved for Launch',
    review: 'Submitted for review',
    launched: 'Launched',
    on_hold: 'On Hold',
    on_going_review: 'On Going Review',
};
export default {
    name: 'AccountDropdown',
    data() {
        return {
            showDropdown: false,
            profileImgUrl: null,
            nameFirstletters: null,
            projects: [],
            statuses: { ...STATUS_CLASS },
        };
    },
    mounted() {
        if ( hasAuthUser() && !_.isEmpty(this.user) ) {
            EventBus.$on('image-updated', this.getProfileInfo);
            this.getProfileInfo();
        }
        document.addEventListener('click', this.closeDropdownIfOpen);
        this.projectList();
    },
    computed: {
        ...mapGetters(['user', 'isProjectChange', 'isProjectChangePreview']),
    },
    methods: {
        // async redirectToProject(projectId = null) {
        //     if ( !projectId ) {
        //         this.$store.dispatch('project', null);
        //         removeStorage('project-id');
        //         setStorage('active-tab', 'basic');
        //     }
        //     await this.$router.replace({ name: 'newProjectId', params: { id: projectId } }).catch(err => {
        //         if ( err.name !== 'NavigationDuplicated' ) {
        //             throw err;
        //         }
        //     });
        // },
        async redirectToProject(projectId = null) {
            await this.$store.dispatch('setProjectOldItem', null);
            if ( projectId ) {
                await this.$store.dispatch('setProjectId', projectId);
            } else {
                await this.$store.dispatch('setProjectId', null);
                await this.$store.dispatch('setActiveTab', 'basic');
                if ( this.$route.name == 'project' ) {
                    //only call if somebody fill up data but still want to still want new and same project router
                    window.location.href = '/project';
                }
            }

            await this.$router.replace({ name: 'project', params: { id: projectId } }).catch(err => {
                if ( err.name !== 'NavigationDuplicated' ) {
                    throw err;
                }
            });
        },
        async redirectToMyProject() {
            await this.$router.replace({ name: 'Account' }).catch(err => {
                if ( err.name !== 'NavigationDuplicated' ) {
                    throw err;
                }
            });
            EventBus.$emit('redirect-my-projects', 'my-project');
        },
        handleSubmitLogout() {
            //before logout first need to check project change set true|false in store
            if ( confirm('Are you sure, you want to logout') ) {
                this.loading = true;
                if ( this.isProjectChangePreview == true ) {
                    this.$store.dispatch('setIsProjectSavePopup', true);
                } else {
                    try {
                        // removeStorage('auth');
                        this.$store.dispatch('user', null);
                        this.$router.push({ name: 'Login' });
                    } catch (error) {
                        this.$snotify.error('Something went wrong');
                    }
                }
            }
        },
        closeDropdownIfOpen(event) {
            if ( typeof this.$refs.dropDownElement !== 'undefined' ) {
                if ( !this.$refs.dropDownElement.contains(event.target) && this.showDropdown ) {
                    this.showDropdown = false;
                }
            }
        },
        async getProfileInfo() {
            let response = await request({
                method: 'GET',
                url: '/profile',
            });
            let { data } = response;
            this.profileImgUrl = data.image?.path;
            this.nameFirstletters = data?.name ? data.name.charAt(0).toUpperCase() : 'NA';
        },
        async projectList() {
            try {
                const response = await request({
                    method: 'GET',
                    url: `/project`,
                });

                if ( response.data ) {
                    this.projects = response.data.map(project => {
                        return {
                            id: project.id,
                            title: project?.title,
                            status: project?.status
                                ? {
                                    name: this.statuses[project.status],
                                    className: project.status.charAt(0) + project.status.slice(1),
                                }
                                : {
                                    className: '',
                                    name: 'N/A',
                                },
                        };
                    });
                }
            } catch (error) {
                console.log(error);
            }
        },
    },
    watch: {
        'isProjectChange': function (newVal, oldVal) {
            if ( newVal == true ) {
                this.projectList();
            }
        },
    }
};
</script>
<style lang="scss" scoped>
@import '@/assets/scss/components/accountDropdown.scss';
</style>
