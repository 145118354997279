<template>
    <header class="base-header">
        <nav
            class="d-flex flex-wrap align-items-center justify-content-beetween justify-content-lg-start navbar-expand-lg p-0 position-static"
        >
            <router-link
                :to="{ name: 'home' }"
                class="d-flex align-items-center mb-2 mb-lg-0 text-decoration-none navbar-brand"
            >
                <img src="../assets/img/medicrowd_logo.svg" alt="Logo" class="header-logo" />
            </router-link>

            <div class="text-end d-flex align-items-center d-block d-md-none">
                <!-- <a href="#" class="nav-link px-3 text-secondary d-block me-3 fs-18">Log in</a> -->
                <AccountDropdown v-if="user" />
                <button
                    :disabled="isCheckNewProjectCurrentRoute && user?.role !== 'admin'"
                    type="button"
                    class="btn main-btn d-block fs-18"
                    @click="redirectRoute"
                >
                    Start Project
                </button>
            </div>
            <button
                class="navbar-toggler"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#navbarSupportedContent"
                aria-controls="navbarSupportedContent"
                aria-expanded="false"
                aria-label="Toggle navigation"
            >
                <span class="navbar-toggler-icon"></span>
            </button>

            <div class="collapse navbar-collapse" id="navbarSupportedContent">
                <ul class="nav col-12 col-lg-auto me-lg-auto mb-2 justify-content-center mb-md-0 ms-4">
                    <li>
                        <router-link :to="{ name: 'howItWorks' }" class="nav-link px-3 text-secondary fs-18"
                        >How it Works
                        </router-link>
                    </li>
                    <li>
                        <router-link :to="{ name: 'news' }" class="nav-link px-3 text-secondary fs-18"
                        >News
                        </router-link>
                    </li>
                    <li>
                        <router-link :to="{ name: 'ourStory' }" class="nav-link px-3 text-secondary fs-18"
                        >Our Story
                        </router-link>
                    </li>
                    <li class="position-relative">
                        <a
                            href="#"
                            class="nav-link px-3 text-secondary dropdown-toggle fs-18"
                            id="navbarDropdownMenuLink"
                            role="button"
                            data-bs-toggle="dropdown"
                            aria-expanded="false"
                        >Browse Projects</a
                        >
                        <ul class="dropdown-menu" aria-labelledby="navbarDropdownMenuLink">
                            <li>
                                <router-link :to="{ name: 'browseProjects' }" class="dropdown-item fs-18">Show All
                                </router-link>
                            </li>
                            <li v-for="(category, index) in categories" :key="index">
                                <router-link :to="{ name: 'browseProjects', params: { id: category.id } }"
                                             class="dropdown-item fs-18">{{ category.name }}
                                </router-link>
                            </li>
                        </ul>
                    </li>
                </ul>

                <form class="col-12 col-lg-auto mb-3 mb-lg-0 me-lg-2 search">
                    <div class="search__wrapper d-flex justify-content-end position-relative animated fs-18">
                        <input type="text" name="search" class="search" placeholder="Search" />
                    </div>
                </form>
                <div class="text-end d-flex align-items-center d-none d-md-flex">
                    <router-link v-if="!user" :to="{ name: 'Login' }"
                                 class="nav-link px-3 text-secondary d-block me-3 fs-18"
                    >Log in
                    </router-link>
                    <AccountDropdown v-if="user" />
                    <button
                        :disabled="isCheckNewProjectCurrentRoute && user?.role !== 'admin'"
                        type="button"
                        class="btn main-btn d-block fs-18"
                        @click="redirectRoute"
                    >
                        Start Project
                    </button>
                </div>
            </div>
        </nav>
    </header>
</template>

<script>
import AccountDropdown from './AccountDropdown.vue';
import { mapGetters } from 'vuex';
import { request } from "@/Util/Request";

export default {
    name: 'Header',
    components: {
        AccountDropdown,
    },
    data() {
        return {
            categories: []
        };
    },
    computed: {
        ...mapGetters(['user']),
        isCheckNewProjectCurrentRoute() {
            return this.$route.name == 'project' ? true : false;
        },
    },
    mounted() {
        this.categoriesDetail();
    },
    methods: {
        async redirectRoute() {
            if ( this.user ) {
                await this.$router.replace({ name: 'project' });
            } else {
                if ( this.$route.name !== 'Login' ) {
                    await this.$router.replace({ name: 'Login' });
                }
            }
        },
        async categoriesDetail() {
            try {
                const response = await request({
                    method: 'GET',
                    url: '/categories'
                });

                const { data } = response;
                this.categories = data;
            } catch (error) {
                this.$snotify.error("Something went wrong");
            }
        },
    },
};
</script>

<style lang="scss" scoped>
@import '../assets/scss/components/header.scss';
</style>
