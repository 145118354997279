import Vue from "vue";
import Vuex from "vuex";
import createPersistedState from "vuex-persistedstate";
import { getStorage, removeStorage, setStorage } from '@/Util/auth';

Vue.use(Vuex);

const state = {
    user: null,
    actionName: null,
    project: null,
    projectItem: null,
    setActiveTab: null,
    getActiveTab: null,
    getProjectId: null,
    setProjectId: null,
    loader: false,
    getProjectPreviewItem: null,
    projectLaunchSuccess: false,
    isProjectChange: false,
    isProjectSaveError: false,
    isProjectSavePopup: 0, // this is only use for account dropdown
    isProjectChangePreview: false
};

const store = new Vuex.Store({
    state,
    getters: {
        user: (state) => {
            return state.user;
        },
        actionName: (state) => {
            return state.actionName;
        },
        project: (state) => {
            return state.project;
        },
        projectItem: (state) => {
            return state.projectItem;
        },
        getActiveTab: (state) => {
            return state.getActiveTab;
        },
        setActiveTab: (state) => {
            return state.setActiveTab;
        },
        getProjectId: (state) => {
            return state.getProjectId;
        },
        setProjectId: (state) => {
            return state.setProjectId;
        },
        setLoader: (state) => {
            return state.loader;
        },
        getLoader: (state) => {
            return state.loader;
        },
        isProjectChange: (state) => {
            return state.isProjectChange;
        },
        isProjectSaveError: (state) => {
            return state.isProjectSaveError;
        },
        getProjectPreviewItem: (state) => {
            return state.getProjectPreviewItem;
        },
        getProjectLaunchSuccess: (state) => {
            return state.projectLaunchSuccess;
        },
        isProjectSavePopup: (state) => {
            return state.isProjectSavePopup;
        },
        isProjectChangePreview: (state) => {
            return state.isProjectChangePreview;
        },
    },
    actions: {
        user: (context, user) => {
            context.commit('user', user);
        },
        actionName: (context, actionName) => {
            context.commit('actionName', actionName);
        },
        project: (context, project) => {
            context.commit('project', project);
        },
        projectItem: (context, project) => {
            context.commit('project', project);
        },
        setActiveTab: (context, tab) => {
            context.commit('setActiveTab', tab);
        },
        setProjectId: (context, id) => {
            context.commit('setProjectId', id);
        },
        setLoader: (context, data) => {
            context.commit('SET_LOADER', data);
        },
        setProjectOldItem: (context, data) => {
            context.commit('SET_PROJECT_LOAD_ITEM', data);
        },
        setProjectLaunchSuccess: (context, data) => {
            context.commit('setProjectLaunchSuccess', data);
        },
        setIsProjectChange: (context, data) => {
            context.commit('SET_IS_PROJECT_CHANGE', data);
        },
        setIsProjectSaveError: (context, data) => {
            context.commit('SET_IS_PROJECT_SAVE_ERROR', data);
        },
        setIsProjectSavePopup: (context, data) => {
            context.commit('SET_IS_PROJECT_SAVE_POPUP', data);
        },
        setIsProjectChangePreview: (context, data) => {
            context.commit('SET_IS_PROJECT_CHANGE_PREVIEW', data);
        },
    },
    mutations: {
        user: (state, user) => {
            state.user = user;
            if ( state.user === null ) {
                removeStorage('auth');
            }
        },
        actionName: (state, actionName) => {
            state.actionName = actionName;
        },
        project: (state, project) => {
            state.project = project;
        },
        projectItem: (state, project) => {
            state.projectItem = project;
        },
        setActiveTab: (state, tab) => {
            state.getActiveTab = tab;
            setStorage('active-tab', tab);
        },
        setProjectId: (state, id) => {
            state.getProjectId = id;
            setStorage('project-id', id);
        },
        SET_LOADER: (state, data) => {
            state.loader = data;
        },
        SET_PROJECT_LOAD_ITEM: (state, data) => {
            state.getProjectPreviewItem = data;
        },
        setProjectLaunchSuccess: (state, data) => {
            state.projectLaunchSuccess = data;
        },
        SET_IS_PROJECT_CHANGE: (state, data) => { //use if project basic submit and title change then update account dropdown project title also
            state.isProjectChange = data;
        },
        SET_IS_PROJECT_SAVE_ERROR: (state, data) => { //use if project alert confirmation box of project (save|leave)
            state.isProjectSaveError = data;
        },
        SET_IS_PROJECT_SAVE_POPUP: (state, data) => { //use if project alert confirmation box of project (save|leave)
            if ( data == true ) {
                state.isProjectSavePopup += 1;
            } else {
                state.isProjectSavePopup = 0;
            }
        },
        SET_IS_PROJECT_CHANGE_PREVIEW: (state, data) => { //use if project alert confirmation box of project (save|leave)
            state.isProjectChangePreview = data;
        },
    },
    plugins: [createPersistedState()]
});

export default store;
